<template>
  <div class="common-table-list systemManage-manage-user">
    <div class="location">
      <router-link style="color: #6e6b7b" to="/">{{
        $t("Welcome")
      }}</router-link> 
      <span> > {{ $t('System Management')}} > </span>
      <span style="color:#3461FF">{{ $t('User') }}</span>
    </div>
    <div class="hd card">
      <div class="handle-button-section">
        <el-button type="primary" size="small" @click="handleAdd">
          <i class="el-icon-plus" />
          {{$t('button.New')}}
        </el-button>
        <el-button type="danger" size="small" @click="handleBatchDel" :disabled="!selection()">
          <i class="el-icon-delete" />
          {{$t('button.Batch Delete')}}
        </el-button>
      </div>
    </div>

    <div class="bd card">
      <!--切换门店 -->
      <div class="table-header">
        <div>
          <span class="text">{{$t('user.Records')}}:{{pageParams.total}}</span>
          <el-select v-model="searchParams.storeType" @change="initData" size="small">
            <el-option :label="$t('ThisStore')" value="2" />
            <el-option :label="$t('ZimenStore')" value="1" />
            <el-option :label="$t('AllStore')" value="0" />
          </el-select>
        </div>
      </div>

      <!-- 表格 -->
      <el-table 
        :data="tableData" 
        style="width: 100%"
        table-layout="fixed"
        v-loading="loading"
        ref="normalTable"
        @sort-change="handleSort"
        >
        <el-table-column width="50"  type="selection" fixed>
          <el-table-column type="selection" width="50"></el-table-column>
        </el-table-column>
        <el-table-column :label="$t('user.Store Name')" prop="storeName" min-width="160" header-align="center">
          <el-table-column  prop="storeName" min-width="160" align="center">
            <template slot="header" slot-scope="scope">
              <el-input v-model="searchParams.storeName" @input="getData" size="mini" clearable/>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('user.User Group')" prop="user_role_id" min-width="180" sortable="custom" header-align="center">
          <el-table-column  prop="userRoleName" min-width="180" align="center">
            <template slot="header" slot-scope="scope">
              <!-- <el-input v-model="searchParams.userRoleId" @input="getData" size="small" placeholder="User Group"/> -->
              <el-select v-model="searchParams.userRoleId" placeholder=""  size="mini" @change="getData" clearable>
                <el-option
                  v-for="item in userGroupList"
                  :key="item.userRoleId"
                  :label="item.roleName"
                  :value="item.userRoleId"
                >
                  <div class="d-flex justify-content-between">
                    <div class="">{{item.roleName}}</div>
                    <div style="color:#ccc">{{item.storeName}}</div>
                  </div>
                </el-option>
              </el-select>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('user.User Account')" prop="login_id" min-width="160" sortable="custom" header-align="center">
          <el-table-column  prop="loginId" min-width="160" align="center">
            <template slot="header" slot-scope="scope">
              <el-input v-model="searchParams.loginId" @input="getData" size="mini" clearable />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('user.User Name')" prop="user_name" min-width="160" sortable="custom" header-align="center">
          <el-table-column  prop="userName" min-width="160" align="center">
            <template slot="header" slot-scope="scope">
              <el-input v-model="searchParams.userName" @input="getData" size="mini" clearable />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('user.Creation Time')" min-width="230" prop="create_time" sortable="custom" header-align="center">
          <el-table-column prop="createTime" min-width="230" align="center">
            <template slot="header" slot-scope="scope">
              <el-date-picker
                v-model="searchParams.createDate"
                type="daterange"
                start-placeholder=""
                end-placeholder=""
                size="mini"
                @change="handleCreateTimeChange"
                placement="bottom-start"
                value-format="yyyy-MM-dd"
              />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column fixed="right" :label="$t('user.Action')" width="120" header-align="center">
          <el-table-column  align="center" width="120" >
            <template slot="header" ></template>
            <template slot="default" slot-scope="{row}">
              <div class="operate">
                <span class="table-icon"  @click="handleDelete(row)">
                  <i class="el-icon-delete"></i>
                </span>
                <span class="table-icon"  @click="handleEdit(row)">
                  <i class="el-icon-edit-outline"></i>
                </span>
                <span class="table-icon"  @click="handleModifyPassword(row)">
                  <i class="el-icon-lock"></i>
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table-column>

      </el-table>

      <!-- 页码 -->
      <pagination :tableInfo="pageParams" @changePage="getData" />

    </div>

    <!-- 创建用户 -->
    <createUser 
      ref="createUser" 
      :editData="editData" 
      @getData="getData"
    >
    </createUser>

    <!-- 修改密码 -->
    <resetPassword ref="resetPassword" :editData="editData" ></resetPassword>

    <!-- 删除弹窗确认 -->
    <deleteConfirmDialog ref="deleteConfirmDialog" @onDeleteConfirm="userDelete"></deleteConfirmDialog>

  </div>
</template>
<script>
  import { userGetList, userGet, userDelete } from "@/libs/api/system-management-user";
  import { getRoleByStoreId } from "@/libs/api/system-management-user-goup";
  import createUser from './createUser'
  import resetPassword from './resetPassword'
  import {commonStore} from '@/store/global-select-store'
  import deleteConfirmDialog from '@/components/delete-confirm-dialog/index'
  import pagination from '@/components/pagination'
  export default {
    name:'manageUser',
    components: {
      createUser,
      resetPassword,
      deleteConfirmDialog,
      pagination
    },
    props: {
      location: {
        type: String,
        default: 'default',
      },
    },
    data() {
      return {
        commonStore,
        userData:JSON.parse(localStorage.getItem('userData')),
        loading:false,
        tableData:[], // 表格数据源
        userGroupList:[], //用户组下拉数据源
        editData:{},
        deleteData:{},
        searchParams:{
          userName:'',//用户名称
          loginId:'',//登录账号   user account 
          storeId:'',//门店id
          storeType:'0',//门店类型，0：所有门店，1：子级门店 2：当前门店
          storeName:'',//门店名称
          isEffect:0,//是否有效
          createDate:['',''],
          startDate:'',//开始时间
          endDate:'',//结束时间
          state: '',//用户状态
          userRoleId:'', //用户分组ID
          orders:[], // 用于排序的字段，允许多组，以JSONArray格式保存,['desc:tagIdentity']
        },
        pageParams:{
          current:1,//当前页
          size:10,//单页显示最大数
          total:0,
          pageSizeArr:['10','20','50','100','200','500','1000'],
        },
      }
    },
    created(){
      this.initData()
      commonStore.setInjectCallBack(this.initData)
    },
    methods: {
      initData(){
        this.getData()
        this.userRoleGetList()
      },
      // 获取数据
      async getData(){
        let { userName,loginId,storeType,isEffect,storeName,createDate,state,orders,userRoleId} = this.searchParams
        let {current,size} = this.pageParams
        let storeId = localStorage.getItem('storeId')
        let params = {
          userName,
          storeId,
          loginId,
          storeType,
          storeName,
          isEffect,
          userRoleId,
          startDate:createDate[0],
          endDate:createDate[1],
          state,
          orders, 
          current,
          size,
        }
        try {
          const res = await userGetList(params)
          let response = res.data
          if(response.state){
            let dataList = response.data.records
            this.tableData = dataList
            this.pageParams.total = response.data.total
          } else{
            this.$message.error(response.message);
          }
        } catch (error) {
          this.$message.error(error.message);
        }
        
      },
      // 获取user Group 下拉数据
      async userRoleGetList(){
        let storeType = this.searchParams.storeType
        let params ={
          storeId: localStorage.getItem("storeId"),
          storeQueryType:storeType
        }
        try {
          const res = await getRoleByStoreId(params)
          let response = res.data
          if(response.state){
            let dataList = response.data
            this.userGroupList = dataList
          } else{
            this.$message.error(response.message);
          }
        } catch (error) {
          this.$message.error(error.message);
        }
        
      },
      // 获取编辑的用户详情 ---从列表上取，暂时不用通过再次调用接口获取
      async userGet(){
        try {
          let params ={
            userId:this.editData.userId
          }
          const res = await userGet(params)
          let response = res.data
          if(response.state){
            this.editData = response.data
            this.$refs.createUser.open()
          } else{
            this.$message.error(response.message);
          }
        } catch (error) {
          this.$message.error(error.message);
        }
        
      },
      // 表格排序
      handleSort(column, prop, order){
        console.log('表格排序',column, prop, order)
        let arr = []
        if(column.order){
          let str1= column.order === "ascending" ? "asc" : "desc";
          str1 = str1 + ':'+ column.prop;
          arr.push(str1)
        } else {
          arr = []
        }
        this.searchParams.orders = arr;
        this.getData()
      },
      // 时间选择范围变更---创建时间
      handleCreateTimeChange(val){
        let searchParams ={
          ...this.searchParams
        }
        if(!val){
          searchParams.createDate = ['','']
        }
        this.searchParams = searchParams
        this.getData()
      },
      // 新增
      handleAdd(){
        this.editData = {}
        this.$refs.createUser.open()
      },
      // 编辑
      handleEdit(row){
        console.log('编辑',row)
        this.editData = row
        this.$refs.createUser.open()
        // this.userGet()
      },
      // 修改密码
      handleModifyPassword(row){
        console.log('修改密码')
        this.editData = row
        this.$refs.resetPassword.open()
      },
      // 多选复选框---获取选中的数量
      selection() {
        return this.$refs.normalTable && this.$refs.normalTable.selection.length
      },
      // 删除  ---批量删除
      handleBatchDel(){
        const selection = this.$refs.normalTable.selection
        let deleteData ={}
        let storeIds = selection.map(it => it.userId)
        deleteData.userId = storeIds.join(',')
        this.deleteData = deleteData
        this.$refs.deleteConfirmDialog.open()
      },
      // 删除  ---单个删除
      handleDelete(row){
        console.log('删除',row)
        this.deleteData = row
        this.$refs.deleteConfirmDialog.open()
      },
      // 删除 ---请求api
      async userDelete(){
        try {
          let {userId} = this.deleteData
          let params = {
            ids:userId
          }
          const res = await userDelete(params)
          let response = res.data
          if(response.state){
            this.$message({
              message: this.$t('commonMessage.DeleteSucess'),
              type:'success',
            });
            this.$refs.deleteConfirmDialog.close()
            this.getData()
          } else{
            this.$refs.deleteConfirmDialog.close()
            this.$message.error(response.message);
          }
        } catch (error) {
          this.$refs.deleteConfirmDialog.close()
          this.$message.error(error.message);
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  .systemManage-manage-user ::v-deep {
    .el-table
      .el-table__fixed-header-wrapper
      .el-table__header
      .is-group
      tr:nth-of-type(2)
      th:nth-of-type(1)
      .cell {
      display: none;
    }
  }
</style>