import { reactive } from '@vue/composition-api'

export const store = reactive({
  customerId:'',
  setCustomerId(data){
    console.log('设置customerId',data)
    this.customerId = data
    this.reloadUserData()
  },
  reloadUserfn:null, // 重新加载右侧方法
  setReloadUser(fn){ // 设置-- 重新加载右侧方法
    this.reloadUserfn = fn
  },
  reloadUserData(){ // 调用-- 重新加载右侧方法
    this.reloadUserfn()
  },
  reLoad:null,
  setReLoadTree(fn){
    this.reLoad = fn
  },
  callReLoadTree(){
    this.reLoad()
  }
})