<template>
  <div class="systemManage-create create-user">
    <el-dialog 
      v-model="dialogVisible" 
      :title="formData.userId ? $t('user.User Details'): $t('user.Create User')" 
      :before-close="handleClose"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="700px"
    >
      <div class="dialog-common-container">
        <el-scrollbar style="height:100%">
          <div class="form-box">
            <el-form 
              label-width="190px"
              style="width: 100%"
              :model="formData"
              ref="form" 
              :rules="rules"
              size="small"
            >
              <div class="section-tit"><div class="flag"></div>{{$t('user.Basic Information')}}</div>
              <el-form-item :label="$t('user.User Name')" prop="userName">
                <el-input 
                  v-model="formData.userName" 
                  clearable 
                />
              </el-form-item>
              <el-form-item :label="$t('user.Login Account')" prop="loginId">
                <el-input 
                  v-model="formData.loginId" 
                  clearable
                  :disabled="formData.userId !== ''"
                />
              </el-form-item>
              <template v-if="!formData.userId">
                <el-form-item :label="$t('user.Password')" prop="password">
                  <el-input
                    v-model="formData.password"
                    type="password"
                    show-password
                  >
                  </el-input>
                </el-form-item>
                <el-form-item :label="$t('user.Confirm Password')" prop="confirmPassword">
                  <el-input
                    v-model="formData.confirmPassword"
                    type="password"
                    placeholder=""
                    show-password
                  >
                  </el-input>
                </el-form-item>
              </template>
              <el-form-item :label="$t('user.Location Store')" prop="storeId">
                <!-- <el-input v-model="formData.storeName" @focus="showSelectStore"  placeholder="Select" /> -->
                <el-select v-model="formData.storeName"  @focus="showSelectStore"  placeholder="">
                  <!-- <el-option label="This Store" value="2" /> -->
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('user.User Group')" prop="userRoleId">
                <el-select v-model="formData.userRoleId"  placeholder="" :disabled="formData.storeId === ''">
                  <el-option
                    v-for="item in userGroupList"
                    :key="item.userRoleId"
                    :label="item.roleName"
                    :value="item.userRoleId"
                  />
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('user.Status')">
                <el-switch v-model="formData.state" />
              </el-form-item>
              
              <div class="section-tit"><div class="flag"></div>{{$t('user.Other')}}</div>
              <el-form-item :label="$t('user.Enable APP To Modify Price')">
                <el-switch v-model="formData.isAppModifyPrice" />
              </el-form-item>
              <el-form-item :label="$t('user.Language')" prop="languageId">
                <el-select v-model="formData.languageId"  placeholder="">
                  <el-option
                    v-for="item in languageList"
                    :key="item.languageId"
                    :label="item.name"
                    :value="item.languageId"
                  />
                </el-select>
              </el-form-item>

              <!-- 保存按钮 -->
              <!-- <div class="form-btn-section">
                <el-button  color="#28C76F"  @click="dialogVisible =false" size="small">{{$t('button.Cancel')}}</el-button>
                <el-button color="#28C76F" type="success" @click="onSubmit" size="small">{{$t('button.Save')}}</el-button>
              </div> -->

            </el-form>
          </div>
        </el-scrollbar>
      </div>
      <!-- 保存按钮 -->
      <div class="form-btn-section">
        <el-button  color="#28C76F"  @click="handleClose" size="small">{{$t('button.Cancel')}}</el-button>
        <el-button color="#28C76F" type="success" @click="onSubmit" size="small">{{$t('button.Save')}}</el-button>
      </div>
    </el-dialog>

    <!-- 选择门店 -->
    <selectStore ref="selectStore" @confirmSelect="handleSelectStore" ></selectStore>
  </div>
</template>
<script>
  import { trim } from 'lodash';
  import { userCreateStoreUser,userUpdateStoreUser } from "@/libs/api/system-management-user"
  import { getRoleByStoreId } from "@/libs/api/system-management-user-goup"
  import { languageGetList } from "@/libs/api/system-management-customer"
  import { checkIsEmail, checkIsPasswordRegex } from "@/utils/common"
  // import selectStore from './selectStore'
  import selectStore from '@/components/select-store/index'
  import md5 from 'js-md5'
  let loading;

  export default {
    name:'createCustomer',
    components: {
      selectStore
    },
    props: {
      statusList: {
        type: Array
      },
      editData:{
        type:Object
      }
    },
    data() {
      return {
        userData:JSON.parse(localStorage.getItem('userData')),
        dialogVisible:false,
        userGroupList:[], //User Group 数据       
        languageList:[],
        initFormData:{
          userId:'',
          storeId:'', // Location Store --所属门店ID
          storeName:'', //  所属门店名称
          userName:'', // 用户名
          loginId:'', //登录账号
          isAppModifyPrice: true, //是否允许app修改价格(0: 允许 1:不允许)
          userRoleId:'', // 用户分组ID
          password:'', // 密码（MD5加密）
          confirmPassword:'',
          state: true,    // 账号状态，用于控制。0：表示正常，1：禁用
          languageId:'' // 默认语言ID
        },
        formData:{
          userId:'',
          storeId:'', // Location Store --所属门店ID
          storeName:'',//  所属门店名称
          userName:'', // 用户名
          loginId:'', //登录账号
          isAppModifyPrice:true, //是否允许app修改价格(0: 允许 1:不允许)
          userRoleId:'', // 用户分组ID
          password:'', // 密码（MD5加密）
          confirmPassword:'',
          state: true,     // 账号状态，用于控制。0：表示正常，1：禁用
          languageId:'' // 默认语言ID
        },
        rules:{
          storeId: [
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' }
          ],
          userName: [
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' },
            { max: 100, message: this.$t('formValidate.maxLength'), trigger: 'blur'}
          ],
          loginId:[
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' },
            { max: 100, message: this.$t('formValidate.maxLength'), trigger: 'blur'},
            { validator: this.checkEmail, trigger: 'blur' }
          ],
          password:[
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' },
            { validator: this.checkPassword, trigger: 'blur' }
          ],
          confirmPassword:[
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' },
            { validator: this.checkPasswordConfirm, trigger: 'blur' }
          ],
          userRoleId:[
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' },
            { max: 100, message: this.$t('formValidate.maxLength'), trigger: 'blur'}
          ],
        },
      }
    },
    created(){
      
    },
    mounted(){
      
    },
    watch: {
      editData: function(val) {
        console.log('--watch--editData',val)
        if(val && val.userId){
          let data ={
            ...val,
            state:val.state === 0 ? true : false,
            isAppModifyPrice:val.isAppModifyPrice === 0 ? true : false,
            password:'Test123456', //编辑时，会校验改字段，但是该字段没有获取值，该值仅限用户检验表单通过
            confirmPassword:'Test123456' ////编辑时，会校验改字段，但是该字段没有获取值，该值仅限用户检验表单通过
          }
          this.formData = data
          this.userRoleGetList()
        } else {
          let obj = {...this.initFormData}
          this.formData = obj
        }
        
      }
    },
    methods: {
      // 打开弹窗
      open(){
        this.dialogVisible = true
        this.getLanguageList()
      },
      // 获取用户组信息User Group---获取当前用户下所有角色
      async userRoleGetList(){
        let params ={
          storeId: this.formData.storeId,
          storeType: 2 ,//门店类型，0：所有门店，1：子级门店 2：当前门店
        }
        try {
          const res = await getRoleByStoreId(params)
          let response = res.data
          if(response.state){
            this.userGroupList = response.data || []
          } else{
            this.$message.error(response.message);
          }
        } catch (error) {
          this.$message.error(error.message);
        }
      },
      // 获取语言列表
      async getLanguageList () {
        try {
          let { data } = await languageGetList();
          if(data.state){
            let list = data.data;
            list = list.map((value) => ({
              ...value,
              text:""
            }))
            localStorage.setItem("languageList", JSON.stringify({languageList: list}));
            this.languageList = list;
          } else {
            this.$message.error(data.message);
          }
        } catch (error) {
          this.$message.error(error.message);
        } 
      },
      // 选择门店--弹窗
      showSelectStore(){
        console.log('选择门店')
        this.$refs.selectStore.open(this.formData.storeId)
      },
      // 选择门店 --确定
      handleSelectStore(storeId,storeObj){
        console.log('选择了门店信息',storeId,storeObj)
        if(this.formData.storeId === storeId){
          return
        }
        let formData ={
          ...this.formData,
          storeName:storeObj.storeName,
          storeId:storeObj.storeId,
          userRoleId:''
        }
        this.formData= formData
        this.userRoleGetList()
      },
      // 关闭弹窗
      handleClose(){
        this.$refs.form.resetFields()
        this.dialogVisible = false
      },
      // 校验 --- 校验邮箱
      checkEmail(rule,value,callback){
        console.log('邮箱value,rule',value,rule,callback)
        if (!value) {
          return callback(new Error('Please input the email address'))
        } else {
          let isEmail = checkIsEmail(value)
          if(!isEmail){
            return callback(new Error(this.$t('login.FormError1')))
          }else {
            callback()
          }
        }
      },
      // 校验 --- 密码
      checkPassword(rule,value,callback){
        console.log('新密码密码',value,rule)
        if (!value) {
          return callback(new Error('Please input password'))
        } else {
          let res = checkIsPasswordRegex(value)
          if(res){
            if(!this.formData.confirmPassword) return
            if(value === this.formData.confirmPassword){
              callback()
            } else {
              return callback(new Error(this.$t('profile.FormError2')))
            }
          } else {
            return callback(new Error(this.$t('profile.FormError1')))
          }
        }
      },
      // 校验 --- 新密码确认
      checkConfirm(rule,value,callback){
        console.log('新密码--确认',value,rule)
        if (!value) {
          return callback(new Error('Please input password'))
        } else {
          if(value === this.formData.password){
            callback()
          } else {
            return callback(new Error(this.$t('profile.FormError2')))
          }
        }
      },
      // 表单提交
      onSubmit(){
        loading = this.$loading({
          text:"loading...",
          lock: true,
          background: 'rgba(0, 0, 0, 0.7)',
        })
        this.$refs.form.validate( valid => {
          if (valid) {
            console.log('表单校验通过',)
            this.userCreate()
          } else {
            loading.close()
            console.log('error submit!!');
            return false
          }
        })
      },
      // 请求--- 创建/修改--用户
      async userCreate(){
        let {userId,storeId,loginId,userName,isAppModifyPrice,userRoleId,password,state,languageId} = this.formData
        let params = {
          storeId,
          loginId:trim(loginId), 
          userName:trim(userName),
          isAppModifyPrice: isAppModifyPrice ? 0:1, 
          userRoleId,
          password:md5(password), 
          state:state ? 0:1,  
          languageId 
        }
        let res = null
        console.log({storeId})
        try {
          if(userId){
            params.userId = userId
            res = await userUpdateStoreUser(params)
          } else {
            res = await userCreateStoreUser(params)
          }
          let response = res.data
          if(response.state){
            let message = this.$t('commonMessage.CreateSucess')
            if(userId){
              message = this.$t('commonMessage.ModifySucess')
            }
            this.$message({
              message: message,
              type:'success',
            });
            this.dialogVisible = false
            this.$emit('getData')
            loading.close()
          } else{
            loading.close()
            this.$message.error(response.message);
          }
        } catch (error) {
          loading.close()
          this.$message.error(error.message);
        }
      },
    }
  }
</script>
<style lang="scss" scoped>
  .systemManage-create.create-user{
    .form-btn-section{
      padding-top: 20px;
      margin-top: 0;
      border-top: 1px solid #E5E5E5;
    }
    ::v-deep .el-form-item__error{
      position: static !important;;
    }
  }
</style>