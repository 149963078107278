<template>
  <div class="manage-custom-left">
    <el-input v-model="searchParams.customerName" :placeholder="$t('user.Input Name Search')" size="small" @input="getData" clearable />
    <div class="manage-custom-tree">
      <el-scrollbar style="height:100%">
        <el-tree 
          :props="defaultProps" 
          node-key="customerId"
          lazy 
          :load="lazyLaod" 
          :data="treeData"
          :highlight-current="true" 
          :expand-on-click-node="false"
          @node-click="handleNodeClick"
          :current-node-key="currentNodeKey"
          ref="treeRef"
        />
      </el-scrollbar>
    </div>
  </div>
</template>
<script>
  import { getListCustomer} from "@/libs/api/system-management-customer";
  import { store }  from '../store/index'
  export default {
    name:'',
    components: {
    },
    props: {},
    data() {
      return {
        store,
        treeData:[],
        filterText:'',
        defaultProps:{
          label: 'customerName',
          children: 'children',
          isLeaf: 'isLeaf',
          class:'active-class'
        },
        currentNodeKey:'',
        searchParams:{
          userName:'',//用户名称
          loginId:'',//登录账号   user account 
          storeId:'',//门店id
          storeType:'0',//门店类型，0：所有门店，1：子级门店 2：当前门店
          storeName:'',//门店名称
          isEffect:0,//是否有效
          createDate:['',''],
          startDate:'',//开始时间
          endDate:'',//结束时间
          state: '',//用户状态
          userRoleId:'', //用户分组ID
          orders:[], // 用于排序的字段，允许多组，以JSONArray格式保存,['desc:tagIdentity']
        },
      }
    },
    created(){
      // this.getData()
      this.init()
      console.log('----created----treeLeft')
    },
    mounted(){
      
    },
    methods: {
      init(){
        store.setReLoadTree(this.getData)
      },
      // 懒加载数据
      lazyLaod(node,resolve){
        if (node.level === 0) {
          this.getData(resolve)
        } else {
          this.loadNextLevel(node,resolve)
        }
      },
      // 获取数据
      async getData(resolve){
        let {customerName,customerCode,customerType,status,searchKey,startDate,endDate} = this.searchParams
        console.log({status})
        console.log({startDate})
        let params = {
          customerName,
          customerCode,
          customerType: parseInt(customerType) || '',
          status:parseInt(status) || '',
          searchKey,
          startDate,
          endDate,
          customerId:''
        }
        try {
          const res = await getListCustomer(params)
          let response = res.data
          console.log('获取客户列表',response)
          if(response.state){
            let dataList = response.data.children
            dataList.forEach(element=> {
              element.isLeaf = !element.hasChildren
            })
            console.log('处理后的数据列表',dataList,typeof resolve)
            // this.treeData = dataList
            if(resolve && typeof resolve === 'function'){
              resolve(dataList)
              this.currentNodeKey = dataList && dataList.length>0 ? dataList[0].customerId : ''
              this.$nextTick(() => {
                this.$refs.treeRef.setCurrentKey(this.currentNodeKey);
              });
            } else {
              this.treeData = dataList
              this.currentNodeKey = dataList && dataList.length>0 ? dataList[0].customerId : ''
              this.$nextTick(() => {
                this.$refs.treeRef.setCurrentKey(this.currentNodeKey); 
              });
            }
            if(dataList && dataList.length>0 ){
              store.setCustomerId(dataList[0].customerId)
            }
            
          } else{
            this.$message.error(response.message);
          }
        } catch (error) {
          this.$message.error(error.message);
        }
        
      },
      // 加载更多层级
      async loadNextLevel(node,resolve){
        console.log('加载更多层级',node)
        try {
          const res = await getListCustomer({customerId:node.data.customerId})
          let response = res.data
          if(response.state){
            let dataList = response.data.children
            dataList.forEach(element => {
              element.isLeaf = !element.hasChildren
              if(element.hasChildren === false) {
                delete element['children']
                delete element['hasChildren']
              }
            })
            resolve(dataList)
          }else{
            this.$message.error(response.message);
          }
        } catch (error) {
          this.$message.error(error.message);
        }
        
      },
      // 点击角色
      handleNodeClick(node){
        console.log(node)
        store.setCustomerId(node.customerId)
      },
      setDefault(id){
        store.setCustomerId(id)
      },
    }
  }
</script>
<style lang="scss" scoped>
  .manage-custom-left{
    padding: 20px;
    .manage-custom-tree{
      padding-top: 20px;
      height: 60vh;
      ::v-deep .el-scrollbar__wrap{
        overflow-x: hidden;
      }
    }
    // 重置样式
    ::v-deep .el-tree-node__content{
      height: 40px;
    }
    ::v-deep  .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content{
      // background-color: #35B871;
      background: whitesmoke;
      color: #35B871;
    }
  }
</style>