<template>
  <div class="common-table-list systemManage-user">
    <template v-if="userType ===203 || userType ===204">
      <manageUser></manageUser>
    </template>
    <template v-else>
      <manageCustom></manageCustom>
    </template>
    
  </div>
</template>
<script>
  import manageUser from './components/manageUser'
  import manageCustom from './components/manageCustom'
  export default {
    name:'',
    components: {
      manageUser,
      manageCustom
    },
    props: {
      location: {
        type: String,
        default: 'default',
      },
    },
    data() {
      return {
        userData:JSON.parse(localStorage.getItem('userData')),
        userType:''
      }
    },
    created(){
      let userData = JSON.parse(localStorage.getItem('userData'))
      this.userType = userData.userType
    },
    methods: {
      
    }
  }
</script>
<style lang="scss" scoped>

</style>