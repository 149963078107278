<template>
  <div class="user-manage-custom-container">
    <div class="location">
      <router-link style="color: #6e6b7b" to="/">{{
        $t("Welcome")
      }}</router-link>
      <span> > {{ $t('System Management')}} > </span>
      <span style="color:#3461FF">{{ $t('User') }}</span>
    </div>
    <div class="user-manage-custom">
      <div class="left card">
        <treeLeft></treeLeft>
      </div>
      <div class="right card">
        <treeRight></treeRight>
      </div>
    </div>
  </div>
</template>
<script>
  import manageUser from './manageUser'
  import treeLeft from './treeLeft'
  import treeRight from './treeRight'
  import { store }  from '../store/index'
  export default {
    name:'',
    components: {
      manageUser,
      treeLeft,
      treeRight
    },
    props: {
      location: {
        type: String,
        default: 'default',
      },
    },
    data() {
      return {
        userData:JSON.parse(localStorage.getItem('userData')),
        store
      }
    },
    created(){
    },
    mounted(){
      
    },
    methods: {
    }
  }
</script>
<style lang="scss" scoped>
  .location{
    margin-bottom: 20px;
  }
  .user-manage-custom{
    
    display: flex;
    .left{
      // min-width: 30%;
      width:260px;
      // height: 300px;
      margin-right: 1.2rem;
      background: #fff;
    }
    .right{
      flex: 1;
      // min-height: 90vh;
    }
  }
</style>