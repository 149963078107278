<template>
  <div class="common-table-list table-container card">
    <div class="bd card">
      <div class="table-header">
        <div><span class="text">{{$t('user.Records')}}:{{pageParams.total}}</span></div>
      </div>

      <!-- 表格 -->
      <el-table 
        :data="tableData" 
        style="width: 100%"
        table-layout="fixed"
        v-loading="loading"
        ref="normalTable"
        @sort-change="handleSort"
        >
        <!-- <el-table-column width="50" type="selection" fixed>
          <el-table-column type="selection" width="50">
            <template slot="header" slot-scope="scope"></template>
          </el-table-column>
        </el-table-column> -->
        <el-table-column :label="$t('customer.Customer Name')" prop="customer_name" min-width="160" header-align="center">
          <el-table-column  prop="customerName" min-width="160" align="center">
            <template slot="header" slot-scope="scope">
              <el-input v-model="searchParams.customerName" @input="getData" size="mini" placeholder="" clearable />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('user.User Group')" prop="user_role_id" min-width="160" sortable="custom" header-align="center">
          <el-table-column  prop="userRoleName" min-width="160" align="center">
            <template slot="header" slot-scope="scope">
              <!-- <el-input v-model="searchParams.userRoleId" @input="getData" size="small" placeholder="User Group"/> -->
              <el-select v-model="searchParams.userRoleId"  placeholder="" size="mini" @change="getData" clearable>
                <el-option
                  v-for="item in userGroupList"
                  :key="item.userRoleId"
                  :label="item.roleName"
                  :value="item.userRoleId"
                />
              </el-select>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('user.User Account')" prop="login_id" min-width="160" sortable="custom" header-align="center">
          <el-table-column  prop="loginId" min-width="160" align="center">
            <template slot="header" slot-scope="scope">
              <el-input v-model="searchParams.loginId" @input="getData" size="mini" placeholder="" clearable />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('user.User Name')" prop="user_name" min-width="160" sortable="custom" header-align="center">
          <el-table-column  prop="userName" min-width="160" align="center">
            <template slot="header" slot-scope="scope">
              <el-input v-model="searchParams.userName" @input="getData" size="mini" placeholder="" clearable />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('user.Creation Time')" min-width="230" prop="create_time" sortable="custom" header-align="center">
          <el-table-column prop="createTime" min-width="230" align="center">
            <template slot="header" slot-scope="scope">
              <el-date-picker
                v-model="searchParams.createDate"
                type="daterange"
                start-placeholder=""
                end-placeholder=""
                size="mini"
                @change="handleCreateTimeChange"
                placement="bottom-start"
                value-format="yyyy-MM-dd"
              />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column fixed="right" :label="$t('user.Action')" width="120" header-align="center">
          <el-table-column  align="center" width="120" >
            <template slot="header" ></template>
            <template slot="default" slot-scope="{row}">
              <div class="operate">
                <!-- <span class="table-icon"  @click="handleDelete(row)">
                  <i class="el-icon-delete"></i>
                </span> -->
                <span class="table-icon"  @click="handleEdit(row)">
                  <i class="el-icon-edit-outline"></i>
                </span>
                <span class="table-icon"  @click="handleModifyPassword(row)">
                  <i class="el-icon-lock"></i>
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>

      <!-- 页码 -->
      <pagination :tableInfo="pageParams" @changePage="getData" />
    </div>

    <!-- 修改密码 -->
    <resetPassword ref="resetPassword" :editData="editData" ></resetPassword>

    <!-- 编辑用户 -->
    <createCustom 
      ref="createCustom" 
      :editData="editData" 
      @getData="getData"
    >
    </createCustom>

    <!-- 删除弹窗确认 -->
    <deleteConfirmDialog ref="deleteConfirmDialog" @onDeleteConfirm="userDelete"></deleteConfirmDialog>

  </div>
</template>
<script>
  import { getAgentUserList, userGet, userDelete, getAllRoleByMerchantId} from "@/libs/api/system-management-user";
  // import { getAllDescendantRole } from "@/libs/api/system-management-user-goup";
  import { store }  from '../store/index'
  import resetPassword from './resetPassword'
  import createCustom from './createCustom'
  import pagination from '@/components/pagination'
  import deleteConfirmDialog from '@/components/delete-confirm-dialog/index'
  export default {
    name:'treeRight',
    components: {
      resetPassword,
      createCustom,
      pagination,
      deleteConfirmDialog
    },
    props: {
      customerId: {
        type: String,
        default: 'default',
      },
    },
    data() {
      return {
        store,
        userData:JSON.parse(localStorage.getItem('userData')),
        loading:false,
        tableData:[], // 表格数据源
        userGroupList:[], //User Group 下拉数据源
        editData:{},
        deleteData:{},
        searchParams:{
          loginId:'', //   User Account  --搜索条件
          userName:'', //用户名称
          customerName:'', //客户名称
          isEffect:0, // 是否有效，0：有效，1：无效
          createDate:['',''],
          state: '',//用户状态 ----全部 
          userRoleId:'', //用户分组ID  ---userGoup
          orders:[], // 用于排序的字段，允许多组，以JSONArray格式保存,['desc:tagIdentity']
        },
        pageParams:{
          current:1,//当前页
          size:10,//单页显示最大数
          total:0,
          pageSizeArr:['10','20','50','100','200','500','1000'],
        },
      }
    },
    created(){
      store.setReloadUser(this.initData)
    },
    mounted(){
      
    },
    watch: {
      // customerId: function(val) {
      //   console.log('----watch---customerId',val)
      //   if(val){
      //     console.log('----watch---customerId',val)
      //     this.getData()
      //   }
      // }
    },
    methods: {
      initData(){
        this.getData()
        this.userGroupGetList()
      },
      // 获取数据
      async getData(){
        let { loginId,userName,customerName,isEffect,state,createDate,userRoleId,orders} = this.searchParams
        let {current,size} = this.pageParams
        // let userData = this.userData
        let params = {
          customerId: store.customerId, //客户Id
          userName, //用户名称
          customerName, //客户名称
          isEffect,
          loginId, 
          startDate:createDate[0],
          endDate:createDate[1],
          state,
          userRoleId, //用户分组ID
          orders, 
          current,
          size,
        }
        try {
          const res = await getAgentUserList(params)
          let response = res.data
          if(response.state){
            let dataList = response.data.records
            this.tableData = dataList
            this.pageParams.total = response.data.total
          } else{
            this.$message.error(response.message);
          }
        } catch (error) {
          this.$message.error(response.message);
        }
      },
      // 获取user Group 下拉数据
      async userGroupGetList(){
        try {
          let params ={
            merchantId: store.customerId,
            storeId: localStorage.getItem('storeId')
          }
          const res = await getAllRoleByMerchantId(params)
          let response = res.data
          if(response.state){
            let dataList = response.data
            this.userGroupList = dataList
          } else{
            this.$message.error(response.message);
          }
        } catch (error) {
          this.$message.error(error.message);
        }
        
      },
      // 表格排序
      handleSort(column, prop, order){
        console.log('表格排序',column, prop, order)
        let arr = []
        if(column.order){
          let str1= column.order === "ascending" ? "asc" : "desc";
          str1 = str1 + ':'+ column.prop;
          arr.push(str1)
        } else {
          arr = []
        }
        this.searchParams.orders = arr;
        this.getData()
      },
      // 时间选择范围变更---创建时间
      handleCreateTimeChange(val){
        let searchParams ={
          ...this.searchParams
        }
        if(!val){
          searchParams.createDate = ['','']
        }
        this.searchParams = searchParams
        this.getData()
      },
      // 多选复选框---获取选中的数量
      selection() {
        return this.$refs.normalTable && this.$refs.normalTable.selection.length
      },
      // 编辑
      handleEdit(row){
        console.log('编辑',row)
        this.editData = row
        this.$refs.createCustom.open()
        // this.userGet()
      },
      // 修改密码
      handleModifyPassword(row){
        console.log('修改密码')
        this.editData = row
        this.$refs.resetPassword.open()
      },
      // 删除  ---批量删除
      alldelt(){
        const selection = this.$refs.normalTable.selection
        let deleteData ={}
        let storeIds = selection.map(it => it.userId)
        deleteData.userId = storeIds.join(',')
        this.deleteData = deleteData
        // this.dialogVisible =true
        this.$refs.deleteConfirmDialog.open()
      },
      // 删除  ---单个删除
      handleDelete(row){
        console.log('删除',row)
        this.deleteData = row
        // this.dialogVisible =true
        this.$refs.deleteConfirmDialog.open()
      },
      // 删除 ---请求api
      async userDelete(){
        try {
          let {userId} = this.deleteData
          let params = {
            ids:userId
          }
          const res = await userDelete(params)
          let response = res.data
          if(response.state){
            this.$message({
              message: this.$t('commonMessage.DeleteSucess'),
              type:'success',
            });
            // this.dialogVisible = false
            this.$refs.deleteConfirmDialog.close()
            this.getData()
            store.callReLoadTree()
          } else{
            this.$refs.deleteConfirmDialog.close()
            // this.dialogVisible = false
            this.$message.error(response.message);
          }
        } catch (error) {
          this.$message.error(response.message);
        } 
      },
    }
  }
</script>
<style lang="scss" scoped>
</style>