<template>
  <div class="systemManage-create create-custom">
    <el-dialog 
      v-model="dialogVisible" 
      :title="formData.userId ? $t('user.User Details'): $t('user.Create User')" 
      :before-close="handleClose"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="700px"
    >
      <div class="dialog-common-container">
        <el-scrollbar style="height:100%">
          <div class="form-box">
            <el-form 
              label-width="190px"
              style="width: 100%"
              :model="formData"
              ref="form" 
              :rules="rules"
              size="small"
            >
              <div class="section-tit"><div class="flag"></div>{{$t('customer.Basic Information')}}</div>
              <el-form-item :label="$t('customer.Customer Name')" prop="customerName">
                <el-input 
                  v-model="formData.customerName" 
                  placeholder="Customer Name"
                  clearable
                  :disabled="formData.userId !== ''"
                />
              </el-form-item>
              <el-form-item :label="$t('customer.User Account')" prop="loginId">
                <el-input 
                  v-model="formData.loginId" 
                  type="numbder"
                  placeholder="Login account"
                  clearable
                  :disabled="formData.userId !== ''"
                />
              </el-form-item>
              <el-form-item :label="$t('customer.User Name')" prop="userName">
                <el-input 
                  v-model="formData.userName" 
                  placeholder="User Name"
                  clearable 
                />
              </el-form-item>
              <el-form-item :label="$t('customer.Status')" >
                <el-switch v-model="formData.state" />
              </el-form-item>
              
              <!-- <div class="section-tit"><div class="flag"></div>其他信息</div> -->
              <el-form-item  :label="$t('customer.Language')"  prop="languageId">
                <el-select v-model="formData.languageId"  placeholder="Select">
                  <el-option
                    v-for="item in languageList"
                    :key="item.languageId"
                    :label="item.name"
                    :value="item.languageId"
                  />
                </el-select>
              </el-form-item>

              <!-- 保存按钮 -->
              <div class="form-btn-section">
                <el-button  color="#28C76F"  @click="dialogVisible =false" size="small">{{$t('button.Cancel')}}</el-button>
                <el-button color="#28C76F" type="success" @click="onSubmit" size="small">{{$t('button.Save')}}</el-button>
              </div>

            </el-form>
          </div>
        </el-scrollbar>
      </div>
    </el-dialog>

  </div>
</template>
<script>
  import { userUpdateAgentUser } from "@/libs/api/system-management-user";
  import { languageGetList } from "@/libs/api/system-management-customer";
  import { trim } from 'lodash';
  // import { checkIsEmail, checkIsPasswordRegex } from "@/utils/common";
  // import md5 from 'js-md5'
  let loading;

  export default {
    name:'createCustomer',
    components: {
    },
    props: {
      statusList: {
        type: Array
      },
      editData:{
        type:Object
      }
    },
    data() {
      return {
        userData:JSON.parse(localStorage.getItem('userData')),
        dialogVisible:false,
        userGroupList:[], //User Group 数据       
        languageList:[],
        initFormData:{
          userId:'', // 用户ID
          userName:'', // 用户名
          state: true, // 账号状态，用于控制。0：表示正常，1：禁用
          languageId:'' // 默认语言ID
        },
        formData:{
          userId:'', // 用户ID
          userName:'', // 用户名
          state: true, // 账号状态，用于控制。0：表示正常，1：禁用
          languageId:'' // 默认语言ID
        },
        rules:{
          userName: [
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' },
            { max: 100, message: this.$t('formValidate.maxLength'), trigger: 'blur'}
          ]
        },
      }
    },
    created(){
      
    },
    mounted(){
      
    },
    watch: {
      editData: function(val) {
        console.log('--watch--editData',val)
        if(val && val.userId){
          let data ={
            ...val,
            state:val.state === 0 ? true : false,
          }
          this.formData = data
        } else {
          let obj = {...this.initFormData}
          this.formData = obj
        }
        
      }
    },
    methods: {
      // 打开弹窗
      open(){
        this.dialogVisible = true
        this.getLanguageList()
      },
      // 获取语言列表
      async getLanguageList () {
        try {
          let { data } = await languageGetList();
          if(data.state){
            let list = data.data;
            list = list.map((value) => ({
              ...value,
              text:""
            }))
            localStorage.setItem("languageList", JSON.stringify({languageList: list}));
            this.languageList = list;
          } else {
            this.$message.error(data.message);
          }
        } catch (error) {
          this.$message.error(error.message);
        } 
      },
      // 关闭弹窗
      handleClose(){
        this.$refs.form.resetFields()
        this.dialogVisible = false
      },
      // 表单提交
      onSubmit(){
        loading = this.$loading({
          text:"loading...",
          lock: true,
          background: 'rgba(0, 0, 0, 0.7)',
        })
        this.$refs.form.validate( valid => {
          if (valid) {
            console.log('表单校验通过',)
            this.userCreate()
          } else {
            loading.close()
            console.log('error submit!!');
            return false
          }
        })
      },
      // 请求--- 创建/修改--用户
      async userCreate(){
        let {userId,userName,state,languageId} = this.formData
        let params = {
          userId,
          userName:trim(userName),
          state:state ? 0:1,  
          languageId 
        }
        let res = null
        try {
          if(userId){
            res = await userUpdateAgentUser(params)
          } else {
            // 预留创建时的请求的api
            // res = await userCreateStoreUser(params)
          }
          let response = res.data
          if(response.state){
            this.$message({
              message: this.$t('commonMessage.ModifySucess'),
              type:'success',
            });
            this.dialogVisible = false
            this.$emit('getData')
            loading.close()
          } else{
            loading.close()
            this.$message.error(response.message);
          }
        } catch (error) {
          loading.close()
          this.$message.error(error.message);
        }
        
      },
    }
  }
</script>
<style lang="scss" scoped>
  .systemManage-create{
    .dialog-common-container{
      // min-height: 600px;
      height: calc(70vh);
      ::v-deep .el-scrollbar__wrap{
        overflow-x: hidden;
        padding-bottom: 40px;
      }
    }
    .form-box{
      width: 450px;
      margin: 0 auto;
    }
    .section-tit{
      position: relative;
      font-weight: 600;
      padding: 10px 10px 10px 20px;
      background: whitesmoke;
      margin-bottom: 20px;
      border-radius: 4px;
      .flag{
        position: absolute;
        width: 4px;
        height: 18px;
        border-radius: 2px;
        background: #35B871;
        top: 0px;
        bottom: 0px;
        left: 10px;
        margin: auto;
      }
    }
    .form-btn-section{
      width: 100%;
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      ::v-deep .el-button{
        width: 110px;
      }
    }
    // 重置样式
    ::v-deep .el-dialog{
      margin-top: 5vh !important;
    }
    .el-select{
      width: 100%;
    }
    .el-button--success{
      width: 100%;
    }
  }
</style>