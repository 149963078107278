<template>
  <div class="systemManage-create reset-password">
    <el-dialog 
      v-model="dialogVisible" 
      :title="$t('user.Reset Password')" 
      :before-close="handleClose"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="700px"
    >
      <div class="form-box">
        <el-form 
          label-width="160px"
          style="width: 100%"
          :model="formData"
          ref="form" 
          :rules="rules"
          size="small"
        >
        <el-form-item :label="$t('user.User Account')">
            <el-input
              v-model="editData.loginId"
              placeholder=""
              disabled
            >
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('user.New Password')" prop="password">
            <el-input
              v-model="formData.password"
              type="password"
              placeholder=""
              show-password
            >
            </el-input>
          </el-form-item>

          <!-- 保存按钮 -->
          <!-- 保存按钮 -->
          <div class="form-btn-section">
            <el-button  color="#28C76F"  @click="handleClose" size="small">{{$t('button.Cancel')}}</el-button>
            <el-button color="#28C76F" type="success" @click="onSubmit" size="small">{{$t('button.Save')}}</el-button>
          </div>

        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { resetPassword } from "@/libs/api/system-management-user";
  import { checkIsPasswordRegex } from "@/utils/common";
  import md5 from 'js-md5'
  let loading;

  export default {
    name:'createCustomer',
    components: {
    },
    props: {
      editData:{
        type:Object
      }
    },
    data() {
      return {
        dialogVisible:false,
        initFormData:{
          userId:'',
          password:'', // 密码（MD5加密）
        },
        formData:{
          userId:'',
          password:'', // 密码（MD5加密）
        },
        rules:{
          password:[
            { required: true, message:  this.$t('formValidate.required fields'), trigger: 'blur' },
            { validator: this.checkPassword, trigger: 'blur' }
          ],
        },
        
      }
    },
    created(){

    },
    mounted(){
      
    },
    watch: {
      editData: function(val) {
        console.log('--watch--editData',val)
        if(val && val.userId){
          let data ={
            ...val
          }
          this.formData = data
        } else {
          let obj = {...this.initFormData}
          this.formData = obj
        }
        
      }
    },
    methods: {
      // 打开弹窗
      open(){
        this.dialogVisible = true
      },
      // 关闭弹窗
      handleClose(){
        this.$refs.form.resetFields()
        this.dialogVisible = false
      },
      // 校验 --- 密码
      checkPassword(rule,value,callback){
        console.log('新密码密码',value,rule)
        if (!value) {
          return callback(new Error('Please input password'))
        } else {
          let res = checkIsPasswordRegex(value)
          if(res){
            callback()
          } else {
            return callback(new Error('密码必须是8-16位，包含来自以下四类中的至少三类的字符：大写字母、小写字母、数字、非字母数字'))
          }
        }
      },
      // 表单提交
      onSubmit(){
        loading = this.$loading({
          text:"loading...",
          lock: true,
          background: 'rgba(0, 0, 0, 0.7)',
        })
        this.$refs.form.validate( valid => {
          if (valid) {
            console.log('表单校验通过',)
            this.resetPassword()
          } else {
            loading.close()
            console.log('error submit!!');
            return false
          }
        })
      },
      // 请求--- 创建用户组/更新用户组
      async resetPassword(){
        try {
          let { userId, password } = this.formData
          let params = {
            userId,
            password:md5(password),
          }
          let res = await resetPassword(params)
          let response = res.data
          if(response.state){
            this.$message({
              message: this.$t('commonMessage.ModifySucess'),
              type:'success',
            });
            this.dialogVisible = false
            // this.$emit('getData')
            loading.close()
          } else{
            loading.close()
            this.$message.error(response.message);
          }
        } catch (error) {
          loading.close()
          this.$message.error(error.message);
        }
        
      },
    }
  }
</script>
<style lang="scss" scoped>
  .systemManage-create{
    .form-box{
      width: 480px;
      margin: 0 auto;
      min-height: 300px;
    }
    .section-tit{
      position: relative;
      font-weight: 600;
      padding: 10px 10px 10px 20px;
      background: whitesmoke;
      margin-bottom: 20px;
      border-radius: 4px;
      .flag{
        position: absolute;
        width: 4px;
        height: 18px;
        border-radius: 2px;
        background: #35B871;
        top: 0px;
        bottom: 0px;
        left: 10px;
        margin: auto;
      }
    }
    .form-btn-section{
      width: 100%;
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      ::v-deep .el-button{
        width: 110px;
      }
    }
    .form-icon{
      display: inline-block;
      width: 16px;
    }
    // 重置样式
    ::v-deep .el-dialog{
      margin-top: 5vh !important;
    }
    ::v-deep .el-dialog__body{
      max-height: calc(82vh);
      overflow-y: scroll;
    }
    .el-button--success{
      width: 100%;
      background: #35B871;
    }
    ::v-deep .el-input__inner:focus {
      border-color:#35B871;
    }
  }
  .systemManage-create.reset-password{
    .form-box{
      padding-top: 60px;
    }
  }
</style>